//Pagination in DOM
//<div id="search_pagination_container"></div>

//Example call from js
//var searchTiles = new pagination('#search_container','section','#search_pagination_container','my_pagination',0,5);
//searchTiles.init();


//Items container id in DOM, Item content class in DOM, Pagination container Id in DOM, Pagination self id, CurrentPage/StartPage, Item per Page
var newsEventsPagination = function(items_container, item_content, pagination_container_id, pagination_id, CurrentPage, ItemPerPage) {
    var items_container = $(items_container);
    var item_content = $(item_content);
    var pagination_container_id = $(pagination_container_id);
    var pagination_id = pagination_id;
    var currentPage = CurrentPage || 1;
    var ItemPerPage = ItemPerPage || 4;

    var self = this;

    function createPaginationStructure(){
        items_container.unbind('repaginate');
        items_container.bind('repaginate', function () {
            items_container.find(item_content).hide().slice((currentPage - 1) * ItemPerPage, currentPage * ItemPerPage).show();
        });

        items_container.trigger('repaginate');

        var numRows = items_container.find(item_content).length;
        var numPages = Math.ceil(numRows / ItemPerPage);
        var $pager = $('<ul class="pagination" id="'+pagination_id+'"></ul>');

        //FirstPage
        var li = $('<li class="navigator_first"></li>');
        var a = $('<a></a>');
        li.appendTo($pager);
        a.bind('click', {newPage: 1}, function (event) {
            var newUrl = window.location.href;
            newUrl = newUrl.replace("page=" + currentPage, "page=" + event.data['newPage']);
            window.location.href = newUrl;
        }).appendTo(li);


        //PrevPage
        var li = $('<li class="navigator_prev"></li>');
        var a = $('<a></a>');
        li.appendTo($pager);
        a.click(function (event) {
            if (currentPage - 1 > -1) {
                var newUrl = window.location.href;
                newUrl = newUrl.replace("page=" + currentPage, "page=" + Number(currentPage - 1));
                window.location.href = newUrl;
            }
        }).appendTo(li);


        //Prev_placeholder...
        var li = $('<li class="prev_placeholder"></li>');
        var a = $('<a></a>');
        li.appendTo($pager).css("display", "none");
        a.html("&hellip;").appendTo(li);


        //OtherPage
        for (var page = 0; page < numPages; page++) {
            var li = $('<li class="navigation_item"></li>');
            var a = $('<a></a>');
            li.appendTo($pager);
            a.text(page + 1).bind('click', {newPage: page + 1}, function (event) {
                var newUrl = window.location.href;
                newUrl = newUrl.replace("page=" + currentPage, "page=" + event.data['newPage']);
                window.location.href = newUrl;
            }).appendTo(li);
        }


        //Next_placeholder...
        var li = $('<li class="next_placeholder"></li>');
        var a = $('<a></a>');
        li.appendTo($pager).css("display", "none");
        a.html('&hellip;').appendTo(li);


        //NextPage
        var li = $('<li class="navigator_next"></li>');
        var a = $('<a></a>');
        li.appendTo($pager);
        a.click(function (event) {
            if (currentPage - 1 < numPages - 1) {
                var newUrl = window.location.href;
                newUrl = newUrl.replace("page=" + currentPage, "page=" + (Number(currentPage) + 1));
                window.location.href = newUrl;
            }
        }).appendTo(li);

        //lastPage
        var li = $('<li class="navigator_last"></li>');
        var a = $('<a></a>');
        li.appendTo($pager);
        a.bind('click', {newPage: numPages}, function (event) {
            var newUrl = window.location.href;
            newUrl = newUrl.replace("page=" + currentPage, "page=" + event.data['newPage']);
            window.location.href = newUrl;
        }).appendTo(li);

        //Place of paginator in DOM
        $pager.insertBefore(pagination_container_id);

        //Set active Page
        $("#"+pagination_id+" li.navigation_item").each(function(index){
            if(index == (currentPage - 1)){
                $(this).addClass('active');
            }
        });
    }

    //Dinamically set view
    function setPaginationView() {
        $("#"+pagination_id+" .navigation_item").css("display", "none");

        if ($("#"+pagination_id+" .navigation_item.active").prev().hasClass("navigation_item")) {
            $("#"+pagination_id+" .navigation_item.active").prev().css("display", "block");
            $("#"+pagination_id+" .prev_placeholder").css("display", "block");
            $("#"+pagination_id+" .navigator_prev").css("display", "block");
            $("#"+pagination_id+" .navigator_first").css("display", "block");
        } else {
            $("#"+pagination_id+" .prev_placeholder").css("display", "none");
            $("#"+pagination_id+" .navigator_prev").css("display", "none");
            $("#"+pagination_id+" .navigator_first").css("display", "none");
        }

        if ($("#"+pagination_id+" .navigation_item.active").prev().prev().hasClass("navigation_item")) {
            $("#"+pagination_id+" .navigation_item.active").prev().prev().css("display", "block");
            $("#"+pagination_id+" .prev_placeholder").css("display", "block");
            $("#"+pagination_id+" .navigator_prev").css("display", "block");
            $("#"+pagination_id+" .navigator_first").css("display", "block");
        } else {
            $("#"+pagination_id+" .prev_placeholder").css("display", "none");
        }

        if ($("#"+pagination_id+" .navigation_item.active").prev().prev().prev().hasClass("navigation_item")) {
            $("#"+pagination_id+" .prev_placeholder").css("display", "block");
            $("#"+pagination_id+" .navigator_prev").css("display", "block");
            $("#"+pagination_id+" .navigator_first").css("display", "block");
        } else {
            $("#"+pagination_id+" .prev_placeholder").css("display", "none");
        }

        $("#"+pagination_id+" .navigation_item.active").css("display", "block");

        if ($("#"+pagination_id+" .navigation_item.active").next().hasClass("navigation_item")) {
            $("#"+pagination_id+" .navigation_item.active").next().css("display", "block");
            $("#"+pagination_id+" .next_placeholder").css("display", "block");
            $("#"+pagination_id+" .navigator_next").css("display", "block");
            $("#"+pagination_id+" .navigator_last").css("display", "block");
        } else {
            $("#"+pagination_id+" .next_placeholder").css("display", "none");
            $("#"+pagination_id+" .navigator_next").css("display", "none");
            $("#"+pagination_id+" .navigator_last").css("display", "none");
        }

        if ($("#"+pagination_id+" .navigation_item.active").next().next().hasClass("navigation_item")) {
            $("#"+pagination_id+" .navigation_item.active").next().next().css("display", "block");
            $("#"+pagination_id+" .next_placeholder").css("display", "block");
            $("#"+pagination_id+" .navigator_next").css("display", "block");
            $("#"+pagination_id+" .navigator_last").css("display", "block");
        } else {
            $("#"+pagination_id+" .next_placeholder").css("display", "none");
        }

        if ($("#"+pagination_id+" .navigation_item.active").next().next().next().hasClass("navigation_item")) {
            $("#"+pagination_id+" .next_placeholder").css("display", "block");
            $("#"+pagination_id+" .navigator_next").css("display", "block");
            $("#"+pagination_id+" .navigator_last").css("display", "block");
        } else {
            $("#"+pagination_id+" .next_placeholder").css("display", "none");
        }
    }

    this.init = function(param_item_content){
        if(param_item_content != undefined){
            item_content = $(param_item_content);
        }
        createPaginationStructure();
        setPaginationView();
    }
}